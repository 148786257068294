import { getOrderedMap } from "common/utils/listUtils";
import { smartDuration } from "common/utils/testUtils";
import { GuideType } from "constants/guides/GuideType";
import { SECONDS } from "constants/time";
import { difference } from "lodash-es";
import { TFunction } from "react-i18next";

import { GuideIntentType, GuideMachineId } from "./enums";
import { GuideIntents, GuideTypes } from "./types";

export const FAKE_GUIDE_TYPES: GuideType[] = [
  GuideType.HOME_IMPROVEMENT,
  GuideType.GUIDE_HELP_ONLY,
  GuideType.PRESET_GROUP_TYPE,
  GuideType.ADD_ROOM_LAYOUT,
];

export const EXCLUDED_GUIDE_RESULTS_MACHINE_IDS: GuideMachineId[] = [
  GuideMachineId.LIGHTING_FIXTURE_CONTROL_COMBINED_CONNECT,
  GuideMachineId.PLACEHOLDER_MACHINE,
  GuideMachineId.ADD_ROOM_LAYOUT,
];

export const GUIDE_TYPES_ORDER = getOrderedMap(Object.values(GuideType));

export const GUIDE_TRANSITION_DURATION = smartDuration(SECONDS * 0.95);
export const GUIDE_TRANSITION_OVERLAY_DURATION = smartDuration(
  Number((SECONDS / 30).toFixed(2))
);

export const getGuideTypes = (options?: { includeFakeCategories: boolean }) => {
  const types = Object.values(GuideType) as GuideTypes;
  return options?.includeFakeCategories
    ? types
    : difference(types, FAKE_GUIDE_TYPES);
};

export const GUIDE_INTENTS_ORDER: GuideIntents = [
  GuideIntentType.REMOVE,
  GuideIntentType.REPLACE,
  GuideIntentType.MOVE,
  GuideIntentType.ADD,
];
export const GUIDE_INTENTS_ORDER_MAP = getOrderedMap(GUIDE_INTENTS_ORDER);

export const GUIDE_INTENTS_AFFECTING_MATERIALS_BUDGET: GuideIntents = [
  GuideIntentType.ADD,
  GuideIntentType.REPLACE,
];

export const GuideTypesToTitleKeysMap = (
  t: TFunction
): Record<GuideType, string> => ({
  [GuideType.DEMOLITION]: t("Demolition"),
  [GuideType.SITEWORK]: t("Sitework"),
  [GuideType.CONCRETE]: t("Concrete"),
  [GuideType.MASONRY]: t("Masonry"),
  [GuideType.FRAMING]: t("Framing"),
  [GuideType.SHEATHING]: t("Sheathing"),
  [GuideType.WATERPROOFING]: t("Waterproofing"),
  [GuideType.MECHANICAL]: t("HVAC"),
  [GuideType.ELECTRICAL]: t("Electrical"),
  [GuideType.PLUMBING]: t("Plumbing"),
  [GuideType.INSULATION]: t("Insulation"),
  [GuideType.VENTILATION]: t("Ventilation"),
  [GuideType.WALL_AND_CEILING_MATERIAL]: t("Wall & Ceiling Material"),
  [GuideType.WINDOWS_AND_DOORS]: t("Windows & Doors"),
  [GuideType.MILLWORK_AND_TRIM]: t("Millwork & Trim"),
  [GuideType.FLOOR_MATERIAL]: t("Floor Material"),
  [GuideType.PAINT_AND_FINISHES]: t("Paint & Finishes"),
  [GuideType.KITCHEN_SPECIFIC]: t("Kitchen Specific"),
  [GuideType.BATHROOM_SPECIFIC]: t("Bathroom Specific"),
  [GuideType.HOME_SECURITY]: t("Home Security"),
  [GuideType.ROOF]: t("Roof"),
  [GuideType.HARDSCAPE]: t("Hardscape"),
  [GuideType.LANDSCAPE]: t("Landscape"),
  [GuideType.WATER_FEATURES]: t("Water features"),
  [GuideType.EXTERIOR]: t("Exterior Facade"),
  [GuideType.HOME_IMPROVEMENT]: t("Home Improvement"),
  [GuideType.GUIDE_HELP_ONLY]: "No title",
  [GuideType.PRESET_GROUP_TYPE]: "No title",
  [GuideType.ADD_ROOM_LAYOUT]: "No title",
});
