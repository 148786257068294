export const ORCHESTRATOR_MACHINE_ID = "orchestrator";
export const PRO_ACCOUNT_FAKE_PROPERTY_ID = 101;

const UNIQ_FABRIC_SEPARATOR = "_";

const uniqFabric = () => {
  let name = 0;

  return (key = "") => {
    name++;

    return `${key}${UNIQ_FABRIC_SEPARATOR}${name}`;
  };
};

const uniq = uniqFabric();

const makeUniqDebuggable = (obj: {}, prefix = "") => {
  if (process.env.NODE_ENV === "production") {
    return;
  }
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== "string") {
      const nestedPrefix = `${prefix}${key}${UNIQ_FABRIC_SEPARATOR}`;
      makeUniqDebuggable(value, nestedPrefix);
    } else if (value.startsWith(UNIQ_FABRIC_SEPARATOR)) {
      obj[key] = `${prefix}${key}${value}`;
    }
  });
};

export const ORCHESTRATOR_STATES = {
  INITIAL: uniq(),
  ERROR: uniq(),
  NOT_FOUND: uniq(),
  COMING_SOON: uniq(),
  NOT_AVAILABLE_MOBILE: uniq(),
  TERMS: uniq(),
  PRIVACY: uniq(),
  SHARE: uniq(),
  LOGIN: uniq("LOGIN"),
  HOME: uniq("HOME"),
  PRO_MARKETING: uniq("PRO_MARKETING"),
  BUSINESS_SIGN_UP: uniq("BUSINESS_SIGN_UP"),
  SIGN_UP: {
    INITIAL: uniq("SIGN_UP_INITIAL"),
    VERIFY: uniq("SIGN_UP_VERIFY"),
    VERIFY_ERROR: uniq("SIGN_UP_VERIFY_ERROR"),
  },
  RESET_PASSWORD: uniq("RESET_PASSWORD"),
  PROPERTY_TRANSFER: uniq(),
  PROPERTY_ADDITION: {
    INITIAL: uniq("PROPERTY_ADDITION_INITIAL"),
    SELECT_ADDRESS: uniq("PROPERTY_ADDITION_SELECT_ADDRESS"),
    ADDRESS_DETAILS: uniq("PROPERTY_ADDITION_ADDRESS_DETAILS"),
    ADDRESS_CONFIRMED: uniq(),
    ADDRESS_REJECTED: uniq(),
    ROOMS_AND_SPACES: uniq(),
    ROOM_IMAGES: uniq(),
  },
  ACCOUNT_SETTINGS: uniq(),
  HELP_CENTER: uniq(),
  MEDIA: uniq(),
  MEDIA_ALBUMS: uniq(),
  MEDIA_ALBUMS_SHARED_WITH_ME: uniq(),
  MEDIA_ALBUMS_SHARED_BY_ME: uniq(),
  MEDIA_ALBUMS_INACTIVE: uniq(),
  GET_INSPIRED: uniq(),
  GET_INSPIRED_CATEGORY: uniq(),
  GET_INSPIRED_CATEGORY_RESULTS: uniq(),
  GET_EDUCATED: {
    MAIN: uniq(),
    PROJECT_GUIDE: uniq(),
    PROJECT_GUIDE_POST: uniq(),
    MY_STUFF: uniq(),
  },
  DASHBOARD: uniq(),
  BUSINESS_DASHBOARD: uniq(),
  DASHBOARD_NO_PROPERTY: uniq(),
  HIPS: {
    ONBOARDING: uniq(),
    DASHBOARD: uniq(),
    ROOM: {
      DEFAULT: uniq("DEFAULT"),
      PLAN_DETAILS: uniq("PLAN_DETAILS"),
      PLAN_DETAILS_OUTPUT: uniq(),
    },
    PLANNING_AREAS: uniq(),
    CREATE_SOW: {
      BASE: uniq(),
      INTRO: uniq(),
      CHOOSE_PRESET: uniq(),
      CHOOSE_INDIVIDUALLY: uniq(),
      CHOOSE_SUB_CATEGORY: uniq(),
      PRESET_GROUP: uniq(),
      OVERVIEW: uniq(),
    },
    BUDGET: uniq(),
  },
  GUIDES: {
    GUIDE: uniq(),
  },
  INSPIRATION: uniq(),
  SHARED_PLAN: uniq(),
};
makeUniqDebuggable(ORCHESTRATOR_STATES);

export const ORCHESTRATOR_ACTIONS = {
  RESTORE_STATE: uniq(),
  SET_STATE: uniq(),
  GO_BACK: uniq(),
  ERROR: {
    HOME: uniq(),
  },
  PROPERTY: {
    SET_PROPERTY_LOCKED: uniq(),
    SET_PROPERTY_UNLOCKED: uniq(),
  },
  PROPERTY_TRANSFER: {
    DASHBOARD: uniq(),
  },
  HOME: {
    GET_EDUCATED: uniq(),
    LOGIN: uniq(),
  },
  PRO_MARKETING: {
    LOGIN: uniq(),
    BUSINESS_SIGN_UP: uniq(),
  },
  NOT_FOUND: {
    HOME: uniq(),
  },
  COMING_SOON: {
    BLOG: uniq(),
    DASHBOARD: uniq(),
  },
  NOT_AVAILABLE_MOBILE: {
    DASHBOARD: uniq(),
  },
  LOGIN: {
    RESET_PASSWORD: uniq(),
    SUCCESS: uniq(),
    SIGN_UP: uniq(),
  },
  SIDEBAR: {
    OPEN: uniq(),
    CLOSE_SIDEBAR: uniq(),
  },
  BUSINESS_SIGN_UP: {
    SET_FAKE_PROPERTY: uniq(),
    LOGIN: uniq(),
    PRO_MARKETING: uniq(),
  },
  ANY_MODAL: {
    OPEN: uniq(),
    CLOSE: uniq(),
  },
  CONFIRMATION_MODAL_SEEN: uniq(),
  SIGN_UP: {
    INITIAL: {
      LOGIN: uniq(),
      PROPERTY_ADDITION: uniq(),
      DASHBOARD: uniq(),
    },
    VERIFY_ERROR: {
      HOME: uniq(),
      HELP: uniq(),
    },
    VERIFY: {
      ROOT: uniq(),
      LOGIN: uniq(),
      VERIFY_ERROR: uniq(),
      ROOMS_AND_SPACES: uniq(),
    },
  },
  RESET_PASSWORD: {
    SUCCESS: uniq(),
    HELP: uniq(),
    RESET_PASSWORD: uniq(),
  },
  PROPERTY_ADDITION: {
    INITIAL: {
      LOGIN: uniq(),
      SELECT_ADDRESS: uniq(),
      DASHBOARD: uniq(),
    },
    SELECT_ADDRESS: {
      ADDRESS_DETAILS: uniq(),
      LOGIN: uniq(),
    },
    ADDRESS_DETAILS: {
      SELECT_ADDRESS: uniq(),
      SET_PROPERTY: uniq(),
      SET_PROPERTY_FIELD: uniq(),
      SET_ROOMS: uniq(),
      CLAIM_SUCCESS: uniq(),
      CLAIM_FAILURE: uniq(),
    },
    ADDRESS_REJECTED: {
      BACK: uniq(),
      HOME: uniq(),
    },
    ADDRESS_CONFIRMED: {
      SIGN_UP: uniq(),
      ROOMS_AND_SPACES: uniq(),
      DASHBOARD: uniq(),
    },
    ROOMS_AND_SPACES: {
      MEDIA: uniq(),
    },
    ROOM_IMAGES: {
      ROOMS_AND_SPACES: uniq(),
      DASHBOARD: uniq(),
      SET_COVER_PHOTOS: uniq(),
      SET_HAS_FINISHED_ONBOARDING: uniq(),
      REMOVE_COVER_PHOTOS: uniq(),
      REMOVE_COVER_PHOTOS_FOR_ROOM: uniq(),
    },
  },
  HEADER: {
    DASHBOARD: uniq(),
    DASHBOARD_SELECT_PROPERTY: uniq(),
    MEDIA: uniq(),
    GET_EDUCATED: uniq(),
    ACCOUNT_SETTINGS: uniq(),
    REPORT_BUG: uniq(),
    HELP: uniq(),
    SUBSCRIBE: uniq(),
    HELP_CENTER: uniq(),
    MAKE_SUGGESTION: uniq(),
    LOG_OUT: uniq(),
    HOME: uniq(),
  },
  PRICES: {
    UPDATE_PRICES: uniq(),
  },
  FOOTER: {
    REPORT_BUG: uniq(),
    MAKE_SUGGESTION: uniq(),
    HELP_CENTER: uniq(),
    HELP: uniq(),
  },
  DRAWER: {
    SET_ACTIVE_PROPERTY: uniq(),
    DASHBOARD: uniq(),
    MEDIA: uniq(),
    GET_EDUCATED: uniq(),
    HELP: uniq(),
    ADD_PROPERTY: uniq(),
  },
  MEDIA: {
    GO_TO_PHOTOS: uniq(),
    GO_TO_SHARED_WITH_ME: uniq(),
    GO_TO_SHARED_BY_ME: uniq(),
    GO_TO_SHARED_INACTIVE: uniq(),
    GO_TO_INSPIRED: uniq(),
    GO_TO_INSPIRED_CATEGORY: uniq(),
    GO_TO_INSPIRED_CATEGORY_RESULTS: uniq(),
    GO_TO_ALBUMS: uniq(),
    OPEN_ALBUM: uniq(),
    SET_COVER_PHOTOS: uniq(),
    SET_PROPERTY_IMAGE: uniq(),
    SAVE_INSPIRATION: uniq(),
    CLEAN_UP_AFTER_UPLOAD_DELETE: uniq(),
    SET_HAS_SEEN_MEDIA_HELPER_TEXT: uniq(),
    SET_HAS_SEEN_MY_ALBUMS_SIDEBAR: uniq(),
  },
  BUSINESS_DASHBOARD: {
    MY_GALLERY: uniq(),
    SHARED_ALBUMS: uniq(),
    SET_CLIENT_IMAGE: uniq(),
  },
  DASHBOARD: {
    MEDIA: uniq(),
    ADD_PROPERTY: uniq(),
    ROOMS_AND_SPACES: uniq(),
    HIPS: {
      CREATE_HIPS: uniq(),
      REMOVE_HIPS: uniq(),
      OPEN_HIPS: uniq(),
    },
    SET_HAS_FINISHED_ONBOARDING: uniq(),
    PROPERTY_TRANSFER_SUCCESS: uniq(),
  },
  DASHBOARD_NO_PROPERTY: {
    ADD_PROPERTY: uniq(),
  },
  EDIT_ROOMS_AND_SPACES: {
    CANCEL: uniq(),
    SAVE_CHANGES: uniq(),
    REMOVE_COVER_PHOTOS_FOR_ROOM: uniq(),
  },
  ROOM: {
    DASHBOARD: uniq(),
  },
  SHARED_PLAN: {
    GOTO_PROFESSIONALS: uniq(),
    GOTO_HOME: uniq(),
    GOTO_LOGIN: uniq(),
    GOTO_SIGNUP: uniq(),
  },
  HIPS: {
    PLAN_DETAILS_OUTPUT: {
      ADD_NOTE: uniq(),
    },
    DASHBOARD: {
      SET_HAS_SEEN_HIP_TOUR: uniq(),
      SET_TITLE: uniq(),
      SET_BUDGET: uniq(),
      SET_RESERVE: uniq(),
      START_PLANNING: uniq(),

      SCOPE_OF_WORK_OVERVIEW: uniq(),
      START_SCOPE_OF_WORK: uniq(),
      FINISH_SCOPE_OF_WORK: uniq(),

      START_GUIDES: uniq(),
      FINISH_GUIDES: uniq(),

      CONTINUE_GUIDES: uniq(),
      FINISH_CONTINUE_GUIDES: uniq(),

      TOUR_ENDED: uniq(),
      OPEN_ROOM: uniq(),
      OPEN_MEDIA_PAGE: uniq(),
      OPEN_BUDGET_PAGE: uniq(),
      OPEN_INDIVIDUAL_CATEGORY: uniq(),
      OPEN_DASHBOARD: uniq(),
    },
    ONBOARDING: {
      DASHBOARD: uniq(),
    },
    ROOM: {
      DASHBOARD: uniq(),
      GUIDE: uniq(),
      NAVIGATE_ROOM: uniq(),
      OPEN_BUDGET_PAGE: uniq(),
      OPEN_SCOPE_OF_WORK: uniq(),
      OPEN_SOW_OVERVIEW: uniq(),
      CREATE_ROOM_LAYOUT: uniq(),
      OPEN_TASKS: uniq(),
      OPEN_TASKS_CATEGORY: uniq(),
      OPEN_ROOM_TASKS_CATEGORY: uniq(),
      REMOVE_GUIDE_CATEGORY: uniq(),
      NAVIGATE_PLAN_DETAILS: uniq(),
    },
    PLANNING_AREAS: {
      BACK: uniq(),
      ADD_ROOM_TO_PLANNING_AREA: uniq(),
      ADD_ALL_ROOM_TO_PLANNING_AREA: uniq(),
      ADD_FILTERED_ROOMS_TO_PLANNING_AREA: uniq(),
      REMOVE_ROOM_FROM_PLANNING_AREA: uniq(),
      REMOVE_ALL_ROOM_FROM_PLANNING_AREA: uniq(),
      OPEN_ROOM: uniq(),
      DONE: uniq(),
    },
    CREATE_SOW: {
      BACK: uniq(),
      OPEN_CHOOSE_FROM_PRESET: uniq(),
      OPEN_CHOOSE_INDIVIDUALLY: uniq(),
      OPEN_CHOOSE_ROOM_INDIVIDUALLY: uniq(),
      OPEN_PRESET_GROUP: uniq(),
      OPEN_PLANNING_AREAS: uniq(),
      OPEN_CHOOSE_SUB_CATEGORY: uniq(),
      OPEN_CHOOSE_ROOM_SUB_CATEGORY: uniq(),
      OPEN_SOW_PAGE: uniq(),
      OPEN_SOW_ROOM_PAGE: uniq(),
      OPEN_OVERVIEW: uniq(),
      OPEN_HIPS_DASHBOARD: uniq(),
      ADD_GUIDE: uniq(),
      ADD_MULTIPLE_GUIDES: uniq(),
      ADD_PRESET_GROUP: uniq(),
      START_OVER_PRESET_GROUP: uniq(),
      REMOVE_MULTIPLE_GUIDES: uniq(),
      REMOVE_GUIDE: uniq(),
      REMOVE_ROOM_FROM_PLANING_AREA: uniq(),
      REMOVE_INTENT: uniq(),
      REMOVE_GUIDE_CATEGORY: uniq(),
      REMOVE_ALL_TASKS: uniq(),
      DONE: uniq(),
      ADD_FAKE_GUIDE: uniq(),
    },
    BUDGET: {
      BACK: uniq(),
      SET_RESERVE: uniq(),
      SET_BUDGET: uniq(),
    },
  },
  GUIDES: {
    GUIDE: {
      SAVE_PROGRESS: uniq(),
      DONE: uniq(),
      EXIT: uniq(),
      SAVE_INSPIRATION_MAPPING: uniq(),
      SAVE_PINNED_PHOTO: uniq(),
      REMOVE_PINNED_PHOTO: uniq(),
      ADD_ROOMS: uniq(),
      SET_HAS_SEEN_GUIDE_CTA: uniq(),
      SET_IS_MEASUREMENTS_SHOWN: uniq(),
      SET_IS_GUIDE_BUDGET_BLOCK_OPEN: uniq(),
    },
  },
  INSPIRATION: {
    SAVE_INSPIRATION_MAPPING: uniq(),
  },
  GET_EDUCATED: {
    GO_TO_MAIN: uniq(),
    GO_TO_PROJECT_GUIDE: uniq(),
    GO_TO_MY_STUFF: uniq(),
    GO_TO_PROJECT_GUIDE_POST: uniq(),
    GO_TO_INSPIRATION: uniq(),
    SET_POST_READ: uniq(),
    REMOVE_POST_READ: uniq(),
    ADD_BOOKMARK: uniq(),
    REMOVE_BOOKMARK: uniq(),
    REMOVE_ALL_BOOKMARK: uniq(),
    SUBSCRIBE: uniq(),
  },
  ACCOUNT_SETTINGS: {
    BACK: uniq(),
    INITIAL: uniq(),
    ADD_PROPERTY: uniq(),
    OPEN_PROPERTY_DASHBOARD: uniq(),
    REMOVE_PROPERTY: uniq(),
  },
  EARLY_ACCESS: {
    REQUEST_EARLY_ACCESS: uniq(),
    PRO_MARKETING: uniq(),
  },
  HELP: {
    BACK: uniq(),
  },
};
makeUniqDebuggable(ORCHESTRATOR_ACTIONS);
